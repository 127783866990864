.homecontent[data-v-3dd2e005] {
  position: relative;
}
.homecontent .home .banner .bannerContent[data-v-3dd2e005] {
  position: relative;
  height: 9rem;
}
.homecontent .home .banner .bannerContent .titlebg[data-v-3dd2e005] {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  top: 3rem;
  width: 80%;
  height: 2rem;
  background-size: 100%;
}
.homecontent .home .banner .bannerContent .applist[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 0.54rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 30;
}
.homecontent .home .banner .bannerContent .applist-item[data-v-3dd2e005] {
  margin-right: 0.32rem;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.homecontent .home .banner .bannerContent .applist-item .name[data-v-3dd2e005] {
  font-size: var(--f14);
  font-weight: 400;
  color: #fefefe;
  text-shadow: 0 0.02rem 0.05rem rgba(0, 0, 0, 0.5);
  text-align: center;
  word-break: keep-all;
}
.homecontent .home .banner .bannerContent .applist-item .icon[data-v-3dd2e005] {
  display: inline-block;
  width: 0.38rem;
  height: 0.38rem;
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 0.12rem;
}
.homecontent .home .banner .bannerContent .callbackOld[data-v-3dd2e005] {
  cursor: pointer;
  position: absolute;
  bottom: 0.54rem;
  left: 85%;
  color: #ffffff;
  background-color: var(--themeColor);
  padding: 0.12rem 0.24rem;
  font-size: var(--f18);
  font-weight: 800;
  border-radius: 0.16rem;
}
.homecontent .home .common[data-v-3dd2e005] {
  padding: 0.72rem 0 0;
  width: 15rem;
  position: relative;
  margin: 0 auto;
}
.homecontent .home .common .container .titleCont[data-v-3dd2e005] {
  margin-bottom: 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.homecontent .home .announcebox .announce[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.homecontent .home .announcebox .announce .announceBox[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.homecontent .home .announcebox .announce .announceBox-left[data-v-3dd2e005] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.homecontent .home .announcebox .announce .announceBox-left-list[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.homecontent .home .announcebox .announce .announceBox-left-list-item[data-v-3dd2e005] {
  width: calc(50% - 0.58rem);
  color: #fff;
  font-size: var(--f16);
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
  padding: 0.24rem;
  background: #f6f6f6;
  color: var(--Contentcolor);
  cursor: pointer;
}
.homecontent .home .announcebox .announce .announceBox-left-list-item .name[data-v-3dd2e005] {
  margin-bottom: 0.14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.homecontent .home .announcebox .announce .announceBox-left-list-item .time[data-v-3dd2e005] {
  color: var(--descColor);
}
.homecontent .home .announcebox .announce .announceBox-left-list-item[data-v-3dd2e005]:hover {
  background: var(--themeColor);
}
.homecontent .home .announcebox .announce .announceBox-left-list-item:hover .name[data-v-3dd2e005],
.homecontent .home .announcebox .announce .announceBox-left-list-item:hover .time[data-v-3dd2e005] {
  color: #fff;
}
.homecontent .home .announcebox .announce .announceBox-left-list-item[data-v-3dd2e005]:nth-last-of-type(-n + 2) {
  margin-bottom: unset;
}
.homecontent .home .announcebox .announce .announceBox-right[data-v-3dd2e005] {
  width: 2.56rem;
  background: url(../../static/img/announce.03496f2f.jpg) no-repeat center center / cover;
}
.homecontent .home .news[data-v-3dd2e005] {
  margin-bottom: 0.72rem;
}
.homecontent .home .news .container .newsContent[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 2.8rem;
}
.homecontent .home .news .container .newsContent-left[data-v-3dd2e005] {
  width: 60%;
  margin-right: 0.8rem;
}
.homecontent .home .news .container .newsContent-left-info[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.26rem;
}
.homecontent .home .news .container .newsContent-left-info .pic[data-v-3dd2e005] {
  margin-right: 0.12rem;
  width: 3.36rem;
  height: 2.15rem;
}
.homecontent .home .news .container .newsContent-left-info .infobox .title[data-v-3dd2e005] {
  margin-bottom: 0.16rem;
  color: var(--themeColor);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 0.3rem;
}
.homecontent .home .news .container .newsContent-left-info .infobox .desc[data-v-3dd2e005] {
  font-size: var(--f14);
  color: var(--Contentcolor);
  margin-bottom: 0.38rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.homecontent .home .news .container .newsContent-left-info .infobox .time[data-v-3dd2e005] {
  font-size: var(--f16);
  font-weight: 400;
  color: var(--descColor);
}
.homecontent .home .news .container .newsContent-left-list .list-item[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 0.01rem solid #e9e9e9;
  padding: 0.12rem 0;
  cursor: pointer;
}
.homecontent .home .news .container .newsContent-left-list .list-item .title[data-v-3dd2e005] {
  font-size: var(--f16);
  color: var(--titleColor);
  width: 76%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.homecontent .home .news .container .newsContent-left-list .list-item .title[data-v-3dd2e005]:hover {
  color: var(--themeColor);
}
.homecontent .home .news .container .newsContent-left-list .list-item .time[data-v-3dd2e005] {
  font-size: var(--f16);
  color: var(--descColor);
}
.homecontent .home .news .container .newsContent-left-list .list-item[data-v-3dd2e005]:last-child {
  border-bottom: 0.01rem solid #e9e9e9;
}
.homecontent .home .news .container .newsContent .swiper-item[data-v-3dd2e005] {
  width: 100%;
  display: block;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.homecontent .home .news .container .newsContent .swiper-item .titlearea[data-v-3dd2e005] {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #000000;
  opacity: 0.6;
  padding: 0.14rem 0 0.32rem;
  width: 100%;
  cursor: pointer;
}
.homecontent .home .news .container .newsContent .swiper-item .titlearea .title[data-v-3dd2e005] {
  color: #fff;
  font-size: var(--f14);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.1rem;
  padding: 0 0.14rem;
}
.homecontent .home .mapbox[data-v-3dd2e005] {
  width: 100%;
  height: 9rem;
  position: relative;
  padding-bottom: 0.46rem;
}
.homecontent .home .mapbox.nationwide[data-v-3dd2e005] {
  background-image: url(../../static/img/nationmap.2668970e.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
.homecontent .home .mapbox.shenzhenbox[data-v-3dd2e005] {
  background-image: url(../../static/img/map1.9fe6281a.png);
  background-size: cover;
  background-position: 50% 0;
}
.homecontent .home .mapbox .map .container .morebtn[data-v-3dd2e005] {
  padding: 0.16rem;
  color: #fff;
  background: var(--themeColor);
  border-radius: 0.16rem;
  cursor: pointer;
  font-size: var(--f18);
  margin-top: 0.1rem;
  position: absolute;
  left: 0;
  bottom: -1rem;
  z-index: 99;
}
.homecontent .home .mapbox .map .container .btnlist[data-v-3dd2e005] {
  position: absolute;
  left: 50%;
  top: 0.7rem;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 99;
}
.homecontent .home .mapbox .map .container .btnlist .btn[data-v-3dd2e005] {
  padding: 0.16rem;
  color: var(--themeColor);
  background: #fff;
  border-radius: 0.16rem;
  cursor: pointer;
  margin-right: 0.2rem;
  font-size: var(--f18);
}
.homecontent .home .mapbox .map .container .btnlist .activebtn[data-v-3dd2e005] {
  color: #fff;
  background: var(--themeColor);
}
.homecontent .home .mapbox .map .container .countrymap[data-v-3dd2e005],
.homecontent .home .mapbox .map .container .shenzhenmap[data-v-3dd2e005] {
  position: relative;
}
.homecontent .home .mapbox .map .container .countrymap .maptitle[data-v-3dd2e005],
.homecontent .home .mapbox .map .container .shenzhenmap .maptitle[data-v-3dd2e005] {
  font-size: 0.5rem;
  font-weight: bold;
  background: -webkit-gradient(linear, left bottom, left top, from(#009fa2), to(#0072c2));
  background: linear-gradient(0deg, #009fa2 0%, #0072c2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homecontent .home .mapbox .map .container .countrymap .infobox[data-v-3dd2e005],
.homecontent .home .mapbox .map .container .shenzhenmap .infobox[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 8.7rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 0.44rem;
}
.homecontent .home .mapbox .map .container .countrymap .infobox .info-item[data-v-3dd2e005],
.homecontent .home .mapbox .map .container .shenzhenmap .infobox .info-item[data-v-3dd2e005] {
  width: calc(50% - 0.42rem);
  margin: 0 0.42rem 0.42rem 0;
}
.homecontent .home .mapbox .map .container .countrymap .infobox .info-item .num[data-v-3dd2e005],
.homecontent .home .mapbox .map .container .shenzhenmap .infobox .info-item .num[data-v-3dd2e005] {
  font-size: 0.32rem;
  font-weight: bold;
  color: var(--themeColor);
  margin-bottom: 0.08rem;
  margin-right: 0.06rem;
}
.homecontent .home .mapbox .map .container .countrymap .infobox .info-item .unit[data-v-3dd2e005],
.homecontent .home .mapbox .map .container .shenzhenmap .infobox .info-item .unit[data-v-3dd2e005] {
  font-size: var(--f16);
  color: var(--themeColor);
}
.homecontent .home .mapbox .map .container .countrymap .infobox .info-item .name[data-v-3dd2e005],
.homecontent .home .mapbox .map .container .shenzhenmap .infobox .info-item .name[data-v-3dd2e005] {
  font-size: var(--f16);
  color: var(--Contentcolor);
}
.homecontent .home .mapbox .map .container .countrymap .maptitle[data-v-3dd2e005] {
  font-size: 0.5rem;
  font-weight: bold;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(100%, #0072c2), color-stop(0%, #009fa2));
  background: linear-gradient(0deg, #0072c2 100%, #009fa2 0%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homecontent .home .mapbox .map .container .countrymap .infobox[data-v-3dd2e005] {
  width: 9rem;
}
.homecontent .home .mapbox .map .container .countrymap .infobox .info-item[data-v-3dd2e005] {
  width: calc(33% - 0.42rem);
}
.homecontent .home .mapbox .map .container .map[data-v-3dd2e005] {
  position: absolute;
  overflow: hidden;
  width: 150%;
  height: 100%;
  right: -35%;
  bottom: 0%;
}
.homecontent .home .sixty .container .sixty-content[data-v-3dd2e005] {
  margin-top: 0.28rem;
  background: url(../../static/img/sixty.3abf6826.png) no-repeat center;
  background-size: 100% 100%;
  width: 100%;
  height: 4.88rem;
  color: #fff;
  position: relative;
}
.homecontent .home .sixty .container .sixty-content .titlebox[data-v-3dd2e005] {
  position: absolute;
  bottom: 0.7rem;
  left: 1.24rem;
}
.homecontent .home .sixty .container .sixty-content .titlebox .title[data-v-3dd2e005] {
  font-size: 0.2rem;
  margin-bottom: 0.28rem;
}
.homecontent .home .sixty .container .sixty-content .titlebox .btn[data-v-3dd2e005] {
  color: #ffffff;
  font-size: var(--f18);
  font-weight: bold;
  padding: 0.08rem 0.42rem;
  background: -webkit-gradient(linear, left top, right top, from(#ff8e08), to(#f65656));
  background: linear-gradient(90deg, #ff8e08, #f65656);
  border-radius: 0.24rem;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.homecontent .home .strength[data-v-3dd2e005] {
  position: relative;
}
.homecontent .home .strength .container .strengthCont[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.homecontent .home .strength .container .strengthCont-box[data-v-3dd2e005] {
  -webkit-box-shadow: 0 0.04rem 0.15rem #cccccc;
  box-shadow: 0 0.04rem 0.15rem #cccccc;
  margin-right: 0.12rem;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #ffffff;
}
.homecontent .home .strength .container .strengthCont-box-pic[data-v-3dd2e005] {
  width: 100%;
  height: 50%;
}
.homecontent .home .strength .container .strengthCont-box-info[data-v-3dd2e005] {
  padding: 0.24rem;
}
.homecontent .home .strength .container .strengthCont-box-info .time[data-v-3dd2e005] {
  font-size: var(--f16);
  color: var(--descColor);
  margin-bottom: 0.24rem;
}
.homecontent .home .strength .container .strengthCont-box-info .title[data-v-3dd2e005] {
  font-family: serif;
  font-weight: bold;
  font-size: 0.24rem;
  color: var(--titleColor);
  line-height: 0.48rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 0.56rem;
}
.homecontent .home .strength .container .strengthCont-box-info .btn[data-v-3dd2e005] {
  height: 0.32rem;
  padding: 0 0.16rem;
  line-height: 0.32rem;
  color: var(--themeColor);
  border: 0.02rem solid var(--themeColor);
  border-radius: 0.16rem;
  font-size: 0.12rem;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}
.homecontent .home .strength .container .strengthCont-box-info:hover .title[data-v-3dd2e005] {
  color: var(--themeColor);
}
.homecontent .home .strength .container .strengthCont-box-info:hover .btn[data-v-3dd2e005] {
  background: var(--themeColor);
  color: #fff !important;
  cursor: pointer;
}
.homecontent .home .strength .container .strengthCont-box-progress[data-v-3dd2e005] {
  padding: 0.4rem 0.28rem 0.36rem 0.3rem;
}
.homecontent .home .strength .container .strengthCont-box-progress-item[data-v-3dd2e005] {
  cursor: pointer;
  border-bottom: 0.01rem solid #e8e8e8;
}
.homecontent .home .strength .container .strengthCont-box-progress-item .title[data-v-3dd2e005] {
  font-size: var(--f18);
  font-weight: 400;
  color: #333333;
  margin-bottom: 0.08rem;
  padding-top: 0.14rem;
}
.homecontent .home .strength .container .strengthCont-box-progress-item .time[data-v-3dd2e005] {
  font-size: var(--f14);
  font-weight: 400;
  color: var(--descColor);
  margin-bottom: 0.1rem;
}
.homecontent .home .strength .container .strengthCont-box-progress-item .more[data-v-3dd2e005] {
  margin-top: 0.08rem;
  font-size: var(--f14);
  color: var(--themeColor);
}
.homecontent .home .strength .container .strengthCont-box-progress-item:hover .title[data-v-3dd2e005] {
  color: var(--themeColor);
}
.homecontent .home .strength .container .strengthCont-box[data-v-3dd2e005]:nth-child(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.homecontent .home .strength .container .strengthCont-box[data-v-3dd2e005]:nth-child(1)::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  top: 50%;
  background: url(../../static/img/strengboxbg.ce85b9ae.jpg) no-repeat center center / cover;
  z-index: -1;
}
.homecontent .home .strength .container .strengthCont-box[data-v-3dd2e005]:nth-child(2) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.homecontent .home .strength .container .strengthCont-box[data-v-3dd2e005]:nth-child(2)::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  top: 0;
  z-index: -1;
  background: url(../../static/img/strengboxbg.ce85b9ae.jpg) no-repeat center center / cover;
}
.homecontent .home .magazine[data-v-3dd2e005] {
  margin-top: 1.2rem;
  background: url(../../static/img/magabg1.9dc77a98.png) no-repeat center center / cover;
  background-position: 0 2.2rem;
}
.homecontent .home .magazine .magazine-content[data-v-3dd2e005] {
  margin-top: 0.5rem;
  position: relative;
  height: 8rem;
  overflow: hidden;
}
.homecontent .home .magazine .magazine-content .magazine-swiper[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.homecontent .home .magazine .magazine-content .magazine-swiper .pic[data-v-3dd2e005] {
  width: 35%;
}
.homecontent .home .magazine .magazine-content .magazine-swiper .pic .picbox[data-v-3dd2e005] {
  position: relative;
}
.homecontent .home .magazine .magazine-content .magazine-swiper .pic .picbox img[data-v-3dd2e005] {
  width: 100%;
  padding: 0.08rem;
  background: rgba(212, 215, 224, 0.8);
  position: relative;
}
.homecontent .home .magazine .magazine-content .magazine-swiper .pic .picbox[data-v-3dd2e005]::after {
  content: "";
  position: absolute;
  right: -0.08rem;
  bottom: 0.32rem;
  display: block;
  background: linear-gradient(315deg, #fff 45%, #aaa 50%, #ccc 56%, #fff 80%);
}
.homecontent .home .magazine .magazine-content .magazine-swiper .pic:hover .picbox[data-v-3dd2e005]::after {
  width: 1.2rem;
  height: 1.2rem;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}
.homecontent .home .magazine .magazine-content .magazine-swiper .textarea[data-v-3dd2e005] {
  margin-left: 1.2rem;
  margin-top: 1.6rem;
  letter-spacing: 0.02rem;
}
.homecontent .home .magazine .magazine-content .magazine-swiper .textarea .title[data-v-3dd2e005] {
  font-size: 0.48rem;
  font-weight: bold;
  color: var(--themeColor);
  margin-bottom: 0.24rem;
}
.homecontent .home .magazine .magazine-content .magazine-swiper .textarea .directory-item[data-v-3dd2e005] {
  color: #666666;
  margin-bottom: 0.24rem;
}
.homecontent .home .magazine .magazine-content .magazine-swiper .textarea .directory-item .direct[data-v-3dd2e005] {
  font-size: var(--f18);
  font-weight: 800;
  margin-bottom: 0.08rem;
}
.homecontent .home .magazine .magazine-content .magazine-swiper .textarea .directory-item .name[data-v-3dd2e005] {
  font-size: var(--f16);
  margin-bottom: 0.04rem;
}
.homecontent .home .magazine .magazine-content .pages[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0.24rem;
}
.homecontent .home .magazine .magazine-content .pages-item[data-v-3dd2e005] {
  display: block;
  width: 0.48rem;
  height: 0.04rem;
  background-color: #ffffff;
  margin-right: 0.24rem;
  cursor: pointer;
}
.homecontent .home .magazine .magazine-content .pages-item[data-v-3dd2e005]:hover {
  background-color: var(--themeColor);
}
.homecontent .home .magazine .magazine-content .pages-item-active[data-v-3dd2e005] {
  background-color: var(--themeColor);
}
.homecontent .home .magazine .magazine-content .turnPage[data-v-3dd2e005] {
  position: absolute;
  right: 0;
  bottom: 0.24rem;
}
.homecontent .home .magazine .magazine-content .turnPage .to[data-v-3dd2e005] {
  padding: 0.12rem 0.16rem;
  color: var(--themeColor);
  border: 0.02rem solid var(--themeColor);
  border-radius: 0.16rem;
  font-size: 0.12rem;
  display: inline;
  text-align: center;
  margin-right: 0.12rem;
  cursor: pointer;
}
.homecontent .home .magazine .magazine-content .turnPage .to[data-v-3dd2e005]:hover {
  background: var(--themeColor);
  color: #fff;
}
.homecontent .home .utilbox[data-v-3dd2e005] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  top: 45%;
  right: 0.08rem;
  text-align: center;
  z-index: 999;
}
.homecontent .home .utilbox-item[data-v-3dd2e005] {
  width: 0.56rem;
  height: 0.56rem;
  margin-bottom: 0.06rem;
  cursor: pointer;
}
.homecontent .home .utilbox-zwfw[data-v-3dd2e005] {
  width: 0.4rem;
  height: 0.4rem;
  margin-bottom: 0.06rem;
  cursor: pointer;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.08rem;
  margin-left: 0.06rem;
  margin-top: 0.06rem;
}
.homecontent .home .utilbox-zwfw img[data-v-3dd2e005] {
  width: 0.22rem;
  height: 0.22rem;
}
.homecontent .bgBlock[data-v-3dd2e005] {
  display: block;
  z-index: -1;
  width: 5.81rem;
  height: 7.7rem;
  position: absolute;
  right: 0;
  top: 9rem;
  background: url(../../static/img/circlebg.1fe1ed1b.png) no-repeat center;
  background-size: 100% 100%;
}
.homecontent .seaBlock[data-v-3dd2e005] {
  display: block;
  position: absolute;
  background: url(../../static/img/sea.7f3e9200.png) no-repeat center;
  background-size: 100% 100%;
  left: 0;
  top: 35.08rem;
  width: 100vw;
  height: 7rem;
  z-index: -1;
}
.homecontent .magazine-left[data-v-3dd2e005] {
  width: 50vw;
  height: 3.45rem;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  background-position: 0 0.8rem;
  background: url(../../static/img/magabg1.9dc77a98.png) no-repeat center center / cover;
}
.homecontent .magazine-right[data-v-3dd2e005] {
  width: 50vw;
  height: 3.45rem;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: url(../../static/img/magabg2.1d97805a.png) no-repeat center;
  background-size: 100% 100%;
}
.map svg[data-v-3dd2e005] {
  position: absolute;
  right: -3.2rem;
  top: -2.5rem;
  height: 158%;
  overflow: hidden;
}
.bayWindow[data-v-3dd2e005] {
  position: fixed;
  left: 0;
  top: 2rem;
  width: 2rem;
  height: 1.5rem;
}
.bayWindow .complaint[data-v-3dd2e005] {
  width: calc(100% - 0.12rem);
  height: calc(100% - 0.12rem);
}