.tabs[data-v-c58ee6e4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: var(--f16);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.tabs .tabs-item[data-v-c58ee6e4] {
  color: var(--Contentcolor);
  margin-right: 0.45rem;
  cursor: pointer;
}
.tabs .tabs-item[data-v-c58ee6e4]:hover {
  color: var(--themeColor);
}
.tabs .activeTab[data-v-c58ee6e4] {
  color: var(--themeColor);
  position: relative;
}
.tabs .activeTab[data-v-c58ee6e4]::after {
  content: "";
  width: 100%;
  height: 0.02rem;
  background: var(--themeColor);
  display: block;
  position: absolute;
  bottom: -0.16rem;
  left: 0;
}
.tabs .more[data-v-c58ee6e4] {
  padding: 0.1rem 0.35rem;
  color: #fff;
  font-weight: bold;
  font-size: var(--f14);
  background: var(--themeColor);
  border-radius: 0.12rem;
  cursor: pointer;
}