.swiperbanner {
  width: 100%;
  height: 100%;
}
.swiperbanner .el-carousel__button {
  display: block;
  width: 0.12rem !important;
  height: 0.18rem !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAAAXNSR0IArs4c6QAAAV1JREFUOE+Fkr0rxXEUxj+PgYxmo9cuSXkpZVUyYVAYvGw2mfwJNhsTlxJRYlAGo0Fh8paXbHYbkTw6+v5u917kTL++53zOc875PeKXsN0LSNJxeVp/AIcJ6P8XsN0BnKXCbknnxdAPBdu7wHAq2pOUfX8/lQC2W4AL4D0BlUCbpOtMpRzYAMaB5VQwA2xKirdSBdsNwA1goDHl79MUOUkPJSPZXgMmgLyk6UjaXgWmgHVJkwXAdh1wW9QtOgcQSplqs6TH7x1srwDRdUvSWPEZbW8Co5mybNenLhVAq6RQKoTtZuAK+ARyAWwDI8CapJj3R9jOA7HDTgAvQHVcJrtEOZEuGHu9BhA26ASGJO3/oTAI7AHnAcwCi8Ad0CPpuWyHGuAEaALmAqgCTsMCCZoHjhLUByyk4kugKztrLXAAtP82EhDFA5KeCl5KSuGd8E2Y8CMpxn9YkvQWzb4Au72MB39HfzQAAAAASUVORK5CYII=) no-repeat center;
  background-size: 100% 100%;
  margin-right: 0.3rem;
}
.swiperbanner .el-carousel__indicator.is-active button {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAASCAYAAABvqT8MAAAAAXNSR0IArs4c6QAAARJJREFUOE+N0s0qBnEUx/HvTyTXYIkSJeVl5QJkaWFj4WVnJzdiZ+ml9JQkG+UC7HhWRCQ710AiP51p/tPzjJlmZjWd//mcc+Y/R1Q8tpcASbopH6sGXOdguRHYngPu8sRFSd1e9K+D7QtgNU+6lJTes1AfsD0N3AMDOfgFZiQ9pi5lcAqsl+buSCpiBbA9ATwBgyXwA0xJeu0byfYxsFF1a8CJpM0C2B4DniuqJx9dJiW9ZSPZPgS2aqqn8JGkbdkez2cfagDf2bfYPgPWGpLT8XmAD2CkJfgMEGsw3xJ0A+wC+y3BXoBh4DZWoAE9AAvpWkeBK2C2BkXyiqT33tWITjv5LsUSxs96ATrAgaSvKPYHhbteCAyVuPUAAAAASUVORK5CYII=) no-repeat center;
}
.swiperbanner .el-carousel__indicators--horizontal {
  left: 50% !important;
  -webkit-transform: translate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
  z-index: 20;
}
.swiperbanner .el-carousel__arrow--left {
  font-size: 0.5rem;
  font-weight: bold;
  background-color: unset;
  color: #ffffff;
  left: 0.36rem;
}
.swiperbanner .el-carousel__arrow--right {
  font-size: 0.5rem;
  font-weight: bold;
  background-color: unset;
  color: #ffffff;
  right: 0.36rem;
}